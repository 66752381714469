import React from "react";
import Layout from "../../components/Layout/Layout";
import SidebarLayout from "../../components/SidebarLayout/SidebarLayout";
import LegalNav from "../../components/LegalNav/LegalNav";

const Content = () => {
  return (
    <>
      <p>
        Our website uses cookies to distinguish you from other users of our
        website. This helps us to provide you with a good experience when you
        browse our website and also allows us to improve our website.
      </p>
      <p>
        A cookie is a small file of letters and numbers that we store on your
        browser or the hard drive of your computer if you agree. Cookies contain
        information that is transferred to your computer's hard drive.
      </p>
      <p>We use the following cookies:</p>
      <ul>
        <li>
          <b>Strictly necessary cookies.</b> These are cookies that are required
          for the operation of our website. They include, for example, cookies
          that enable you to log into secure areas of our website, use a
          shopping cart or make use of e-billing services.
        </li>
        <li>
          <b>Analytical or performance cookies.</b> These allow us to recognise
          and count the number of visitors and to see how visitors move around
          our website when they are using it. This helps us to improve the way
          our website works, for example, by ensuring that users are finding
          what they are looking for easily.
        </li>
      </ul>

      <p>
        Please note that the following third parties may also use cookies, over
        which we have no control. These named third parties may include, for
        example, advertising networks and providers of external services like
        web traffic analysis services. These third party cookies are likely to
        be analytical cookies or performance cookies or targeting cookies
      </p>
      <p>
        Google Analytics – These cookies are used for web traffic analysis and
        are not controlled by us. Google analytics cookie descriptions can be
        found here:
        https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage
      </p>
      <p>
        You can block cookies by activating the setting on your browser that
        allows you to refuse the setting of all or some cookies. However, if you
        use your browser settings to block all cookies (including essential
        cookies) you may not be able to access all or parts of our website.
      </p>
    </>
  );
};

const CookiesPage = () => {
  return (
    <Layout>
      <SidebarLayout
        title='Cookie Policy'
        nav={<LegalNav />}
        content={<Content />}
      />
    </Layout>
  );
};

export default CookiesPage;
